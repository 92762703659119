@import '@/style/_mixin.sass'

=space($direction, $count)
  @for $index from 1 through $count
    .#{$direction}#{$index}
      margin-#{$direction}: $index * 10px

      &.padding
        margin-#{$direction}: 0
        padding-#{$direction}: $index * 10px

///R
+space(top, 12)
+space(bottom, 12)
