@import '@/style/_mixin.sass'

.root
  display: flex
  height: 100vh
  align-items: center
  justify-content: center
  text-align: center

.content
  max-width: 460px

.message
  padding-bottom: 20px
